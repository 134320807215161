import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function MainMenu( {options} ){

    const [ selected, setSelected ] = useState('');
    const [ interacted, setInteracted ] = useState(false);
    const navigate = useNavigate();

    function onSelect(e){
        setInteracted(true);
        // console.log(e);
        if (selected===e){
            // navigate.
            console.log('go',e);
            navigate('/'+e);
        }
        setSelected(e);
    }

    useEffect( ()=> {
        let timer = null;
        function goNext(){
            console.log('go next', selected);
            let next = options[0].id;
            for (var i=0;i<options.length-1;i++){
                if (selected===options[i].id) {
                    next = options[i+1].id;
                    break;
                }
            }
            setSelected(next);
        }
        function startTimer(){
            timer = setTimeout( goNext, 5000 );
        }
        // function stopTimer(){
        //     clearTimeout(timer);
        // }

        const interval = selected==='' ? 30000 : 8000;

        if (!interacted) {
            // startTimer();
            timer = setTimeout( goNext, interval );
        }
        return ()=> {
            clearTimeout(timer);
        }
    }, [selected, interacted]);

    return (
        <ul className="mainmenu">
            {options.map(opt=>
                <li key={opt.id} onClick={()=>onSelect(opt.id)} className={opt.id===selected?'selected':''} style={{
                    "--highlight": opt.color
                }} >
                    <img src={opt.img} alt={opt.label} />
                    <div>
                        <h2>{opt.label}</h2>
                        <p>{opt.description}</p>
                        <span>Select to learn more</span>
                    </div>
                </li>
            )}
        </ul>
    )
}