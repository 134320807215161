export default function TimelineIntro( { data }){

    return (
        <div className="timeline-intro">
            <div>
                <img src={data.img} alt={data.title} />
            </div>
            <div>
                <h2>{data.title}</h2>
                <h3>{data.subhead}</h3>
                <p dangerouslySetInnerHTML={{__html: data.description}} />
                <p dangerouslySetInnerHTML={{__html: data.credits}} className="credits" />
            </div>
        </div>
    )
}