export default function TimelineMilestone( { data, color, index, action, out }){
    const dir = data.timeline_y / Math.abs(data.timeline_y);
    const inactive = data.slides.length===0;
    return (
        <div className={`timeline-milestone ${out?'out':''} ${inactive?'inactive':''}`} style={{ left: (data.timeline_x+44) +'px', 
            "--top": (data.timeline_y - (44*dir))+'px', 
            "--len": (Math.abs(data.timeline_y) - 84)+'px',
            "--direction": dir,
            "--anchor": data.timeline_anchor+'px',
            "--index": index }} >
            <div onClick={()=>inactive?null:action(data.id)}>{data.year}</div>
            <p className={data.timeline_align} dangerouslySetInnerHTML={{__html:data.label}}></p>
            {/* line */}
        </div>
    )
}