import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "./Modal";

import TimelineIntro from "./TimelineIntro";
import TimelineMilestone from "./TimelineMilestone";

export default function Timeline( { data, colors }){
    const navigate = useNavigate();
    const params = useParams();
    const [ thisYear, setThisYear ] = useState(0);
    const [ thisSlide, setThisSlide ] = useState(null);
    const [ lastSlide, setLastSlide ] = useState(null);
    const [ thisArtifact, setThisArtifact ] = useState(null);

    const [ slideDirection, setSlideDirection ] = useState(0);
    // const thisyear = params.year || 0;
    // const thisslide = data.list.find(e=>e.year===thisyear);

    const [ anchor, setAnchor ] = useState(null);
    const [ dragging, setDragging ] = useState(0);

    function goHome(){
        navigate('/');
    }

    useEffect( ()=>{
        
        const comingYear = params.year || 0;
        // console.log('lo?',params, thisYear, comingYear );
        if ( comingYear !== thisYear ) {
            setThisYear( comingYear );
            setThisSlide( data.list.find(e=>e.year===comingYear) );
            setLastSlide( thisSlide );

            if (comingYear>thisYear) {
                setSlideDirection(1);
            } else {
                setSlideDirection(-1);
            }
        } else {
            if ( params.artifactId ) {
                const a = thisSlide.artifacts.find( e=>e.id===params.artifactId );
                console.log('set artifact',params.artifactId, a);
                
                // check if twin artifact exists.
                if (a.twin){
                    console.log('has twin', a.twin);
                    const twin = thisSlide.artifacts.find( e=>e.id===a.twin );
                    a.twin_img = twin.slides[0] 
                        ? twin.slides[0].img 
                        : twin.aside[0]
                            ? twin.aside[0].img
                            : "";
                    a.twin_description = twin.label;
                    
                    if (twin.aside) a.twin_description += "<small><b>Image credit</b>: "+twin.aside[0].credit + "</small>";
                    a.twin_active = twin.slides[0] ? true : false;
                }
                setThisArtifact( a );
            } else {
                setThisArtifact(null);
            }
        }
    }, [params]);

    function prevSlide(){
        const slideindex = data.list.findIndex(e=>e.year===thisYear);
        if (slideindex>0) {
            console.log('go',slideindex,data.path+'/'+data.list[ slideindex-1 ].year);
            navigate('/'+data.path+'/'+data.list[ slideindex-1 ].year);
        } else {
            navigate('/'+data.path);
        }
    }
    function nextSlide(){
        const slideindex = data.list.findIndex(e=>e.year===thisYear);
        if (slideindex>-1) {
            console.log('go',slideindex,data.path+'/'+data.list[ slideindex+1 ].year);
            navigate('/'+data.path+'/'+data.list[ slideindex+1 ].year);
        } else {
            console.log('go',data.path+'/'+data.list[0].year);
            navigate('/'+data.path+'/'+data.list[0].year);
        }
    }
    function isFirstSlide(){
        return thisYear===0;
    }
    function isLastSlide(){
        const slideindex = data.list.findIndex(e=>e.year===thisYear);
        return slideindex+1>=data.list.length;
    }
    
    function backToMain(){
        navigate('/'+data.path+'/'+thisSlide.year);
    }

    function onClickMilestone(id){
        console.log(thisYear,id);
        navigate('/'+data.path+'/'+thisYear+'/'+id);
    }

    function getImageStyle(slide,i){
        const styleObj = {"--index":i};
        if (slide.timeline_z) styleObj.zIndex = 1;
        return styleObj;
    }

    function onlyCenturies(value,index,array){
        return array.findIndex(el=>el.century===value.century) === index;
    }

    function touchStart(e){
        // console.log('down');
        const xx = e.screenX || e.touches[0].screenX;
        setAnchor(xx);
    }
    function drag(e){
        if (anchor){
            
            const xx = e.screenX || e.touches[0].screenX;
            setDragging( Math.max( -10, Math.min( 10, xx-anchor ) ) );
            // listRef.current.scrollTop = page * (1080 - (32+86)) - (yy-anchor);
        }
    }
    function touchEnd(e){
        // console.log('up',e);
        const xx = e.screenX || e.changedTouches[0].screenX;
        if ( Math.abs(xx - anchor) > 1 ){
            console.log('up');
            e.preventDefault();
            e.stopPropagation();
            if ( Math.abs(xx - anchor) > 100 ){
                if (xx < anchor) {
                    // if (page<2) setPage(page+1);
                    console.log('go forward');
                    if (!isLastSlide()) nextSlide();
                    // listRef.current.scrollTo( {top: (page+1) * (1080 - (32+86)), behavior:'smooth'} );
                } else {
                    // if (page>0) setPage(page-1);
                    console.log('go back');
                    if (!isFirstSlide()) prevSlide();
                    // listRef.current.scrollTo( {top: (page-1) * (1080 - (32+86)), behavior:'smooth'} );
                }
            } else {
                // listRef.current.scrollTo( {top: page * (1080 - (32+86)), behavior:'smooth'} );
            }
            // setTimeout( ()=>setDragging(0), 50);
            setDragging(0);
        }
        setAnchor(null);
    }

    return (
        <div className="timeline" style={{ "--txtcolor":data.scheme==='dark'?"#ffffff":"#000000", "--accent0": colors[0], "--accent1": colors[1], "--accent2": colors[2], "--accent3": colors[3] }} >
            <button onClick={goHome} className="close-btn" />
            {isFirstSlide() && <TimelineIntro data={data.intro} />}
            {!isFirstSlide() && 
                <div className="timeline" onTouchStart={touchStart} onTouchMove={drag} onTouchEnd={touchEnd} 
                onMouseDown={touchStart} onMouseMove={drag} onMouseUp={touchEnd}>
                    <div className="bg-lines">
                    </div>
                    <div className={`layers moving-${slideDirection} ${thisSlide.year}`}>

                        {/* render century graphic */}
                        {data.list.filter(onlyCenturies).map(century=>{
                            if (century.century===thisSlide.century){
                                return (<img src={century.img} alt={`year ${century.year}`} id={`year-${century.century}`} className="image-layer this-year" />);
                            } else if (lastSlide && century.century===lastSlide.century && thisSlide.century!==lastSlide.century){
                                return (<img src={century.img} alt={`year ${century.year}`} id={`year-${century.century}`} className="image-layer prev-year" />);
                            } else {
                                return null;
                            }
                        })}

                        {/* render lastSlide images */}
                        {lastSlide && lastSlide.artifacts.map( (slide,i) => slide.img ? <img src={slide.img} key={`img-${slide.id}`} style={getImageStyle(slide,i)} className="image-layer out" alt={slide.label} /> : <></> )}
                        
                        {/* render thisSlide images */}
                        {thisSlide.artifacts.map( (slide,i) => slide.img ? <img src={slide.img} key={`img-${slide.id}`} style={getImageStyle(slide,i)} className="image-layer" alt={slide.label} /> : <></> )}
                    {/* </div>
                    <div className="milestones"> */}

                        {/* render lastSlide milestones */}
                        {lastSlide && lastSlide.artifacts.map( (slide,i) => <TimelineMilestone data={slide} color={colors[1]} key={`ms-${slide.id}`} index={i} out={true} /> )}

                        {/* render thisSlide milestones */}
                        {thisSlide.artifacts.map( (slide,i) => <TimelineMilestone data={slide} color={colors[1]} key={`ms-${slide.id}`} index={i} action={onClickMilestone} /> )}

                        <h4 style={{"--drag":dragging}}>{data.intro.title}</h4>
                        {data.timeline_label_1 && <h5>{data.timeline_label_1}</h5>}
                        {data.timeline_label_2 && <h6>{data.timeline_label_2}</h6>}
                        <span style={{"--drag":dragging}}>Touch a date to explore</span>
                    </div>
                    
                </div>}

            {!isFirstSlide() && <button onClick={prevSlide} className="prev-btn"></button>}
            {!isLastSlide() && <button onClick={nextSlide} className="next-btn"></button>}

            {thisArtifact && <Modal artifact={thisArtifact} backToMain={backToMain} accent={ colors[0] } label="Image" goTwin={onClickMilestone} />}
        </div>
    )
}