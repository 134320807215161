import { MemoryRouter, Route, Routes } from'react-router-dom';
import { useEffect, useState } from 'react';
import MainMenu from './components/MainMenu';
import Artifacts from './components/Artifacts';
import Timeline from './components/Timeline';
import './App.scss';

import bg1 from './img/bg1.png';
import bg2 from './img/bg2.png';
import bg3 from './img/bg3.png';

function App() {

  const [ config, setConfig ] = useState(null);

  useEffect( ()=>{
    fetch('/config.json',{
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then((res)=>res.json())
      .then((data)=>setConfig(data))
      .catch(_=>null);
  }, []);

  return (
    <div className="App">
      <div className="background">
        <img src={bg1} alt="background 1" className="layer1" />
        <img src={bg2} alt="background 2" className="layer2" />
        <img src={bg3} alt="background 3" className="layer3" />
      </div>
      {config===null
        ? <div>...loading...</div>
        : <MemoryRouter>
          <Routes>
            <Route path="/" element={<MainMenu options={config.mainmenu} />} />
            <Route path="/canada/:year?/:artifactId?" element={<Timeline data={config.canada} colors={[config.canada.color,config.canada.accent1,config.canada.accent2,config.canada.accent3]} />} />
            <Route path="/toronto/:year?/:artifactId?" element={<Timeline data={config.toronto} colors={[config.toronto.color,config.toronto.accent1,config.toronto.accent2,config.toronto.accent3]} />} />
            <Route path="/women/:year?/:artifactId?" element={<Timeline data={config.women} colors={[config.women.color,config.women.accent1,config.women.accent2,config.women.accent3]} />} />
            <Route path="/artifacts/:artifactId?" element={<Artifacts list={config.artifacts.list} color={config.artifacts.color} />} />
          </Routes>
        </MemoryRouter>
      }
    </div>
  );
}

export default App;
