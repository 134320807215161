import { useState } from "react";

export default function Modal( {artifact, backToMain, accent, label, goTwin} ){
    const [ slide, setSlide ] = useState(artifact.default_slide || 0);

    const slabel = artifact.slides[slide].label || artifact.label;

    return (
        <div className="modal" style={{ "--accent": accent }}>
            <img src={artifact.slides[slide].img} alt={artifact.label} />
            {artifact.year && <em className={artifact.year.length>8?'long':''}>{artifact.year}</em>}

            {artifact.twin && <aside>
                    <img src={artifact.twin_img} alt={artifact.twin_label} />
                    <div>
                    <h4>{artifact.twin_label} Historic Event</h4>
                    <p dangerouslySetInnerHTML={{__html:artifact.twin_description}}></p>
                    {artifact.twin_active && <button onClick={()=>goTwin(artifact.twin)}>Touch to explore</button>}
                    </div>
                </aside>}
            <ul>
            {artifact.slides.map( (s,i) => 
                <li className={i===slide?'selected':''} onClick={()=>setSlide(i)}>{label} { i+1 }</li>
            )}
            </ul>
            <div>
                <div>
                    <h3 dangerouslySetInnerHTML={{__html:slabel}}></h3>
                    <span>{artifact.slides[slide].material}</span>
                    {artifact.slides[slide].credit && <small dangerouslySetInnerHTML={{__html:"<b>Image Credit:</b> "+artifact.slides[slide].credit}}></small>}
                </div>
                <p dangerouslySetInnerHTML={{__html:artifact.slides[slide].description}}></p>
            </div>

            <button className="close-btn" onClick={backToMain} />
        </div>
    )
}