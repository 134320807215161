import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import Modal from "./Modal";

export default function Artifacts( {list,color} ){
    const navigate = useNavigate();
    const params = useParams();
    const [ anchor, setAnchor ] = useState(null);
    const [ dragging, setDragging ] = useState(false);
    const [ page, setPage ] = useState(0);
    const listRef = useRef(null);
    
    const pageLabels = ["Upper","Middle","Lower"];

    function goHome(){
        navigate('/');
    }
    function onClick(id){
        console.log(id, dragging);
        if (id==='td') return;
        if (dragging) return; // don't click if finishing a drag action.
        navigate('/artifacts/'+id);
    }
    function backToMain(){
        navigate('/artifacts/');
    }

    const artifact = params.artifactId
        ? list.find(e=>e.id===params.artifactId)
        : null;

    function touchStart(e){
        // console.log('down');
        const yy = e.screenY || e.touches[0].screenY;
        setAnchor(yy);
    }
    function drag(e){
        if (anchor){
            setDragging( true );
            const yy = e.screenY || e.touches[0].screenY;
            listRef.current.scrollTop = page * (1080 - (32+86)) - (yy-anchor);
        }
    }
    function touchEnd(e){
        // console.log('up',e);
        const yy = e.screenY || e.changedTouches[0].screenY;
        if ( Math.abs(yy - anchor) > 1 ){
            console.log('up');
            e.preventDefault();
            e.stopPropagation();
            if ( Math.abs(yy - anchor) > 150 ){
                if (yy < anchor) {
                    if (page<2) setPage(page+1);
                    console.log('goto',(page+1),(page+1) * (1080 - (32+86)));
                    listRef.current.scrollTo( {top: (page+1) * (1080 - (32+86)), behavior:'smooth'} );
                } else {
                    if (page>0) setPage(page-1);
                    console.log('goto',(page-1),(page-1) * (1080 - (32+86)));
                    listRef.current.scrollTo( {top: (page-1) * (1080 - (32+86)), behavior:'smooth'} );
                }
            } else {
                listRef.current.scrollTo( {top: page * (1080 - (32+86)), behavior:'smooth'} );
            }
            setTimeout( ()=>setDragging(false), 50);
        }
        setAnchor(null);
    }
    function prevPage(){
        const nPage = Math.max(0,page-1);
        setPage(nPage);
        listRef.current.scrollTo( {top: nPage * (1080 - (32+86)), behavior:'smooth'} );
    }
    function nextPage(){
        const nPage = Math.min(2,page+1);
        setPage(nPage);
        listRef.current.scrollTo( {top: nPage * (1080 - (32+86)), behavior:'smooth'} );
    }

    return (
        <div className="artifacts">
            <div className="banner">
                <label>{pageLabels[page]} Shelves : <span>Touch an object to explore</span></label>
                <button onClick={goHome} className="close-btn"></button>
            </div>
            <ul className="list" onTouchStart={touchStart} onTouchMove={drag} onTouchEnd={touchEnd} 
                onMouseDown={touchStart} onMouseMove={drag} onMouseUp={touchEnd} ref={listRef}>
                {list.map(obj=>
                    <li className={obj.size} key={obj.id} onClick={()=>onClick(obj.id)}>
                        <img src={obj.img} alt={obj.label} draggable="false" />
                        {obj.shelf && <span>{obj.shelf}</span>}
                    </li>
                )}
            </ul>

            {page>0 && <button onClick={prevPage} className="prev-btn"></button>}
            {page<2 && <button onClick={nextPage} className="next-btn"></button>}

            {params.artifactId && <Modal artifact={artifact} backToMain={backToMain} accent={ color } label="Artifact" />}
        </div>
    )
}